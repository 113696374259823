import React from "react";
import "../assets/scss/index.scss";
import Acnavbar from "../components/Acnavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/footer";
import Contact from "../components/Contact";
import ProductDescription from "../components/ProductDescription";
import boat from "../assets/images/ourStoryBoat.webp";
import boatMob from "../assets/images/boatMob.webp";
import bdeSahab from "../assets/images/bde-sahab.webp";
import choteSarkar from "../assets/images/chote-sarkar.webp";
import linkedin from "../assets/images/linkedinBlue.webp";
import twitter from "../assets/images/twitterBlue.webp";
import MeetOurTeam from "../components/MeetOurTeam";
import { Container } from "react-bootstrap";
import Seo from "../shared/seo";
import Wrapper from "../components/Wrapper";
import '../assets/scss/ourStory.scss'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"

function about() {
  
  return (
    <Wrapper>
    <Seo
      title="Our Story: Empowering Businesses Through Innovation"
      description="Discover the journey of Softobotics, from humble beginnings to becoming a leading provider of innovative IT solutions for business success." />
      <div className="overflow-hidden">
        <Acnavbar home={false} pathname={'about-us'} logo={""} logoLogic={false} />
        <ProductDescription
          header="Empowering Businesses Through Innovation"
          headerText="Discover the journey of Softobotics, from humble beginnings to becoming a leading provider of innovative IT solutions for business success."
          showButton={true}
          topheader="OUR STORY"
        />
        <div className="aboutBackground bg-cover overflow-visible ">
          <Container>
            <div className="d-flex postion-absolute flex-column  flex-lg-row">
              <div
                className="ps-md-4  position-relative aboutImagePosition "
              >
                <LazyLoadImage effect="blur"
                  src={boat}
                  alt="Boat"
                  className="d-none d-lg-block d-xl-block d-md-none" />
                  <div className="text-center">
                  <LazyLoadImage effect="blur"
                    src={boatMob}
                    alt="Boat"
                    className="d-lg-none d-sm-block d-xl-none d-md-block rotateImageAbout w-90 mx-auto m-0 ms-md-0 " />
                  </div>
              </div>
              <div className="pt-3 ps-4">
                <div className=" fw-700 fnt-24 lh-48 pt-md-5 p-1 OurStoryHeading">
                  Our Story
                </div>
                <div className="d-flex flex-column p-2 p-mb-5 fw-400 fnt-18 fnt-sm-14 lh-24  me-0 me-mb-3  ">
                  <div className="mb-md-4 mb-2 ">
                    Welcome to Softobotics, a company founded on the belief that
                    technology should be used to create positive change in the
                    world. Our journey began in 2018 with a small team of
                    passionate developers who wanted to make a difference. From
                    the very beginning, we've been committed to delivering
                    innovative solutions that help businesses achieve their goals
                    and make a lasting impact on their communities.
                  </div>
                  <div className="mb-md-4 mb-2">
                    Over the years, we've grown into a global team of experts in a
                    wide range of technologies and industries. We' ve worked with
                    companies of all sizes, from startups to Fortune 500
                    companies, and we've built a reputation for delivering results
                    that exceed expectations. Our clients know that they can count
                    on us to provide them with cutting-edge solutions that are
                    tailored to their specific needs.
                  </div>
                  <div className="mb-md-4 mb-2">
                    At Softobotics, we believe that our success is built on the
                    success of our clients. That's why we're committed to building
                    long-term partnerships with the businesses we work with. We
                    take the time to understand their goals and challenges, and we
                    work closely with them to develop solutions that are tailored
                    to their unique needs. Our team is always striving to learn
                    and grow, so that we can stay ahead of the curve and deliver
                    the best possible results for our clients.
                  </div>
                  <div className="mb-md-4 mb-2">
                    But our commitment to making a difference doesn't stop with
                    our clients. We also believe in creating a positive work
                    environment that nurtures our employees' talents and passions.
                    We offer a range of benefits and perks that help our team
                    members feel valued and supported, from flexible work
                    arrangements to wellness programs to regular team outings. We
                    believe that by investing in our employees, we can create a
                    culture of collaboration, creativity, and innovation that
                    benefits everyone.
                  </div>
                  <div className="mb-md-4 mb-2">
                    Thank you for considering Softobotics for your technology
                    needs. We look forward to working with you to build a better
                    future.
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="common-background bg-cover">
          <Container>
            <div className="d-flex justify-content-center ">
              <div className=" mt-1 mt-md-2 color-white fw-700 fnt-24 lh-56  px-lg-5 pt-4 mobHeadingAboutUs text-center">
                We have come a long way since we started
              </div>
            </div>
            <div className="d-flex row  justify-content-between mt-md-1">
              <div className="col-6 col-md-4 d-flex align-items-center justify-content-center subAchivementAboutUs flex-column">
                <div className="fw-700 fnt-32 lh-56  color-white mobHeadingAboutUsCount ">
                  148+
                </div>
                <div className="fw-700 fnt-20 lh-56  color-white mobHeadingAboutUs">
                  Projects Completed
                </div>
              </div>
              <div className="col-6 col-md-4 d-flex align-items-center justify-content-center  subAchivementAboutUs flex-column">
                <div className="fw-700 fnt-32 lh-56  color-white  mobHeadingAboutUsCount ">
                  174+
                </div>
                <div className="fw-700 fnt-20 lh-56  color-white mobHeadingAboutUs">
                  Apps and Solutions
                </div>
              </div>
              <div className="col-12  col-md-4 d-flex align-items-center justify-content-center subAchivementAboutUs flex-column pb-md-0 pb-5">
                <div className="fw-700 fnt-32 lh-56 color-white  mobHeadingAboutUsCount">
                  {" "}
                  50+
                </div>
                <div className="fw-700 fnt-20 lh-56  color-white mobHeadingAboutUs">
                  Employees
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className=" aboutBackground bg-cover d-flex flex-column overflow-visible">
          <Container>
            <div className="text-center fw-700 fnt-24 lh-56 py-md-5 mt-2 nav-link-blue fnt-sm-14 lh-sm-14">
              Meet Our Leaders
            </div>
            <div className="d-flex col-12  mb-2">
              <div className="col-6 d-flex flex-column ps-md-5  ps-3 pb-4  align-items-center h-100">
                <div className="founder">
                <LazyLoadImage effect="blur" src={bdeSahab} alt="Gaurav Kunal" className="profileImage rounded-circle" />
                </div>
                <div className="text-center fw-700 fnt-20 lh-32 fnt-sm-12 lh-sm-12 p-1">
                  Gaurav Kunal
                </div>
                <div className="text-center fw-500 fnt-20 lh-32   fnt-sm-12 lh-sm-12">
                  Founder
                </div>
                <div className="d-flex  justify-content-center pb-md-3 gap-md-2 ">
                  <a
                    href="https://www.linkedin.com/in/gaurav-kunal/"
                    target="_blank"
                  >
                    <LazyLoadImage effect="blur" src={linkedin} alt="Linkedin" className="mobIconAbout pt-1" />
                  </a>
                  <a href="https://twitter.com/SoftoboticsTech/">
                    <LazyLoadImage effect="blur" src={twitter} alt="Twitter" className="mobIconAbout pt-1" />
                  </a>
                </div>
              </div>
              <div className=" col-6 d-flex flex-column pe-md-5 pe-3 pb-md-5 align-items-center h-100">
              <div className="founder">
                <LazyLoadImage effect="blur" src={choteSarkar} alt="Ankit Kumar" className="profileImage rounded-circle" />
                </div>
                <div className="text-center fw-700 fnt-20 lh-32 fnt-sm-12 lh-sm-12 p-1">
                  Ankit Kumar
                </div>
                <div className="text-center fw-500 fnt-20 lh-32   fnt-sm-12 lh-sm-12">
                  Co-Founder
                </div>
                <div className="d-flex justify-content-center pb-md-3 gap-md-2">
                  <a
                    href="https://www.linkedin.com/in/aankit1311/"
                    target="_blank"
                  >
                    <LazyLoadImage effect="blur" src={linkedin} alt="Linkedin" className="mobIconAbout pt-1" />
                  </a>
                  <a href="">
                    <LazyLoadImage effect="blur" src={twitter} alt="Twitter" className="mobIconAbout pt-1" />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <MeetOurTeam home={false} />
        <Contact />
        <Footer />
      </div>
      </Wrapper>
  );
}

export default about;
